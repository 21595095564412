@import "/src/styles/mixin";

.modal {
  @media screen and (max-width: 768px) {
    padding: 12px 12px;
  }
}

.modal_container {
  @media screen and (max-width: 425px) {
    max-width: calc(100vw - 50px);
    padding: 0 0;
  }
}

.wrap {
  width: calc(100vw - 146px);
  max-width: 912px;
  gap: 24px;
  max-height: 500px;
  overflow: auto;
  @media screen and (max-width: 768px) {
    width: calc(100vw - 110px);
  }
  @media screen and (max-width: 425px) {
    width: calc(100vw - 40px);
  }

  &::-webkit-scrollbar {
    width: 2px;
  }
}

.table {
  @include font14;
  border-collapse: collapse;

  &Wrap {
    margin-bottom: 24px;
    overflow-y: hidden;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 2px;
    }
  }

  &Panel {
    position: relative;
    box-sizing: border-box;
    min-width: 100%;
    min-height: 100%;
    width: fit-content;
  }

  tbody {
    background-color: var(--blue-background-color);
  }

  th {
    padding: 6px 12px;
    height: 61px;
    max-width: 300px;
    max-height: 61px;
    position: sticky;
    left: 0;
    background-color: var(--grey-lines-color);
    overflow: hidden;
  }

  td {
    max-width: 150px;
    min-width: 100px;
    padding: 6px 16px;
    text-align: center;
  }

  tr {
    border-bottom: 1px solid var(--grey-lines-color);
  }
}

.subtitle {
  @include font16Bold;
  margin-bottom: 24px;
}

.text_wrapper {
  margin-bottom: 12px;
  @include font14;

  .text_title {
    font-weight: 700;
  }

  &wrapper {
    display: flex;
    flex-direction: column;
  }
}

.description {
  display: flex;
  gap: 32px;
  flex-wrap: nowrap;

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.img {
  width: 100%;
  max-width: 340px;
  border-radius: var(--border-radius-medium);
}
