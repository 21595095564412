@import "/src/styles/mixin";

.react-select {
  z-index: 4;

  &__control {
    border-radius: var(--border-radius-small) !important;
    display: flex !important;
    flex-wrap: nowrap !important;

    &:hover {
      border-color: var(--black-color) !important;
    }

    &--is-focused {
      box-shadow: unset !important;
      border-color: var(--black-color) !important;
    }

    &--menu-is-open {
      border-color: var(--black-color) !important;
    }
  }

  &__placeholder {
    @include font14;
    color: var(--grey-color) !important;
  }

  &__value {
    &-container {
      padding: 9px 16px !important;
      min-width: 90px !important;
    }
  }

  &__option {
    @include font14;
    padding: 9px 16px !important;
    transition: background 0.2s ease;

    &:hover {
      background-color: var(--blue-background-color);
    }

    &--is-focused,
    &--is-selected {
      color: var(--black-color) !important;
      background-color: var(--blue-background-color) !important;
    }
  }

  &__menu {
    width: auto !important;
    min-width: 100% !important;
    z-index: 11 !important;
  }
}
