@import "/src/styles/mixin";

.modal {
  height: calc(100% - 306px);
  max-height: 774px;
  overflow: hidden;
  padding: 40px 60px;

  @media screen and (max-width: 1365px) {
    max-height: 896px;
    height: calc(100% - 206px);
    padding: 40px 50px;
  }

  @media screen and (max-width: 1023px) {
    max-height: 1136px;
    height: calc(100% - 232px);
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    height: 100%;
    padding: 50px 14px 20px;
    width: 100%;
    border-radius: 0;
  }
}

.modal_container {
  overflow: hidden;
  padding: 0 0;

  @media screen and (max-width: 750px) {
    padding: 0 0;
  }
}

.header {
  justify-content: flex-start;
  margin-bottom: 4px;
}

.title {
  margin-bottom: 0;
}

.wrap {
  width: 893px;
  max-height: 702px;
  height: calc(100% - 30px);

  @media screen and (max-width: 1365px) {
    width: 728px;
  }

  @media screen and (max-width: 1023px) {
    width: 546px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    max-height: 100%;
  }

  .text {
    @include font14;
    color: var(--grey-third);
    margin-bottom: 20px;

    @media screen and (min-width: 320px) and (max-width: 767px) {
      height: 66px;
    }
  }

  .inputInfoWrap {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 1365px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 8px;

    &Text {
      @include font13;
      font-weight: 400;
      color: var(--grey-third);
      margin-bottom: 4px;

      @media screen and (max-width: 456px) {
        span {
          display: none;
        }
      }
    }
  }
}

.input {
  padding: 16px 16px 8px !important;

  &Wrap {
    width: 546px;

    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }
}

.defaultCities {
  height: calc(100% - 120px);
  width: 100%;
  overflow: auto;

  @media screen and (max-width: 1365px) {
    height: calc(100% - 150px);
  }

  @media screen and (max-width: 1023px) {
    height: calc(100% - 180px);
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    height: calc(100% - 200px);
  }

  &List {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    row-gap: 12px;
    column-gap: 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: fit-content;
    max-height: 528px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: auto;
    width: 100%;

    @media screen and (max-width: 1365px) {
      max-height: 620px;
    }

    @media screen and (max-width: 1023px) {
      max-height: 836px;
    }

    @media screen and (min-width: 320px) and (max-width: 767px) {
      max-height: 1230px;
      column-gap: 9px;
    }
  }

  &Button {
    @include font14;
    font-weight: 600 !important;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 8px;

    .empty {
      width: 17px;
      height: 100%;
    }

    .region {
      color: var(--grey-color);
    }

    @media screen and (min-width: 320px) and (max-width: 767px) {
      gap: 4px;
      @include font13;
      font-weight: 500 !important;
    }
  }
}
