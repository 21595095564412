.modalContainer {
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: 0;
  }
}

.wrap {
  width: 100%;
  max-width: 500px;

  @media screen and (max-width: 547px) {
    min-width: calc(100vw - 40px);
  }
}
