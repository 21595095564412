@import "/src/styles/mixin";

.modalContainer {
  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: 0;
  }
}

.wrap {
  width: calc(100vw - 146px);
  max-width: 584px;
  gap: 24px;

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    width: calc(100vw - 60px);
  }
}

.text {
  @include font14;
  margin-bottom: 12px;
}

.list {
  padding-left: 20px;
  margin-bottom: 12px;
  list-style-type: circle;
}

.item {
  @include font14;
}

.button {
  @include font14;
  display: block;
  margin: 0 auto;
  padding: 12px 60px;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--secondary-color);
}
